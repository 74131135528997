<template>
  <div class="agreement">
    <div class="header">
      <van-row gutter="10">
        <van-col span="14" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />条款和隐私
        </van-col>
      </van-row>
    </div>
    <div class="container clearfix" id="">
      <div class="user_protocol__box">
        <p class="user_protocol__title">
          本平台是指域名为“ wenlvanquan.com”的平台。
        </p>
        <p class="user_protocol__text">
          本公司在此特别提醒用户认真阅读、充分理解本协议各条款，特别是其中所涉及的免除、限制本公司责任的条款、对用户权利限制条款、争议解决和法律适用等。请用户审慎阅读并选择接受或不接受本协议（未成年人应在监护人陪同下阅读）。除非用户接受本协议所有条款，否则用户无权使用本公司于本协议下所提供的服务。用户在本平台注册、登录或使用本平台服务，即表示用户完全接受本协议的全部条款，并同意接受其约束。本协议适用于网站的用户（以下简称“用户”，“您”），若您使用网站（包括但不限于以任何形式接通、进入、浏览网站，或者使用网站的各项服务、功能等），您的使用行为将被视为对本用户协议全部内容的知晓、认可并同意遵守。
        </p>
        <p class="user_protocol__title">1、接受</p>
        <p class="user_protocol__text">
          1.1
          当用户您使用网站时，您已经完全知晓、认可并同意遵守本协议中的条款，选择访问或使用有关服务，将视为同意接受本协议全部条款的约束。
        </p>
        <p class="user_protocol__text">
          1.2
          网站有权在必要时更新本协议。网站保留对网站结构、服务、功能等进行调整的权利。除非特别声明，新产品、新功能和新服务，均无条件的适用本协议。无论本协议的每次更新还是服务内容调整，都会在网站即刻发布，无需另行同追您并立即生效。如果您拒绝接受新的协议或者服务，您必须立即放弃使用网站；若您继续使用网站，则表面您已完全知晓、认可并同意遵守本协议中的条款。
        </p>
        <p class="user_protocol__title">2、用户</p>
        <p class="user_protocol__text">
          2.1 符合下列条件之一的个人、组织才能成为本平台的用户：
        </p>
        <p class="user_protocol__text sub">
          2.1.1
          是在中国大陆（含港澳台地区）依法设立、有效存续且产品合法的自然人、法人及其他组织。若您不具备此资格，请不要使用网站。用户应当具备使用本平台、享受服务的完全民事行为能力，并承诺对相关行为承担相应的法律责任。
          
        </p>
        <p class="user_protocol__text">
          2.2
          不具备上述资格的人不得成为本平台用户。本公司一经发现，有权立即注销该用户并网站有权禁止被注销取消用户资格的自然人、法人及其他组织再次使用网站。
          
        </p>
        <p class="user_protocol__title">3、注册条款</p>
        <p class="user_protocol__text">
          3.1 
          用户可以通过在本平台免费注册帐号使用本平台提供的各项服务，注册时应根据本平台的要求，提供真实、准确、即时、完整的个人信息，包括但不限于您的姓名或名称、联系地址、联系电话、电子邮箱地址等。如因注册信息不真实而引起的问题由用户本人承担，本平台不负任何责任并有权暂停或终止用户的帐号。
        </p>
        <p class="user_protocol__text">
          3.2 
          注册成功后，用户将获得帐号及其密码。作为您在本平台使用及享受服务时唯一的身份标识。用户需自行妥善保管账户和密码，不得将自身帐号转让、转借给其他第三人使用，对该帐号及其密码负保管义务，并对由此引起的纠纷、损失和法律责任担则。
        </p>
        <p class="user_protocol__text">
          3.3 如用户代表其他自然人、法人或其他组织在网站上注册账户，则需声明并保证已获得授权并有权使前述法律主体受本协议的约束。
        </p>
        <p class="user_protocol__title">4、使用条款</p>
        <p class="user_protocol__text">
          4.1 使用本平台时，用户须遵守国家的法律法规和规章制度，遵守网站有关协议、规定、规则、程序和惯例，不得利用网站从事任何非法或不正当活动。用户不得在本平台发布各类违法或违规信息，包括但不限于商品信息、社区帖子、商品留言，评价内容等。
        </p>
        <p class="user_protocol__text">
          4.2 
          用户确认提供的注册信息等资料系真实、准确、完整、合法，该资料包括但不限于真实姓名或名称、身份证号、联系电话、地址、邮政编码等；用户保证本平台及其他第三方可以通过上述联系方式与自己进行联系；用户也应在相关资料实际变更时，予以及时更新。本公司对用户提供的信息予以保密。
        </p>
        <p class="user_protocol__text">
          4.3 用户有权根据本协议的规定以及网站发布的相关规则 在本平台上查询相关信息、发表使用体验、上传商品图片、参加各类活动，以及享受本网站提供的其它服务。
        </p>
        <p class="user_protocol__text">
          4.4
          用户有义务在注册及享受服务时提供真实、详尽、准确的资料/信息（包括但不限于电子邮件地址、联系 电话、联系地址、邮政编码等），并保证资料/信息的及时、有效更新，使得网站可以通过上述联系方式与用户本人进行联系。
        </p>
        <p class="user_protocol__text">
          4.5
          网站的任何通知都将以电子邮件形式发送至用户的电子邮箱（您同意，如您修改原注册时提交的电子邮箱地址、并通过网站审核的，以新电子邮箱地址为准）。该电子邮件进入前述电子邮箱接收系统时，视为送达。 
        </p>
        <p class="user_protocol__text">
          4.6
          网站有权审核、发布或删除会员提交的信息。网站将尽力核查会员提交的信息，但并不能完全保证信息的真实性、准确性和合法性，同时也不承担由此引至的任何法律责任。
        </p>
        <p class="user_protocol__text">
          4.7
          网站含有与其他网站的链接。但并不能控制这些网站及其所提供的资源，对链接网站上的内容、广告、服务、产品信息的真实、准确、即时、完整性不做任何承诺，并且对链接网站不承担任何法律责任。 
        </p>
        <p class="user_protocol__text">
          4.8 用户同意，对于其上传到本平台的任何内容（包括但不限于文字、图片、视频），网站具有免费的、永久的、不可撤销的、非独家的和完全的许可和再许可的权利，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品，传播、 表演和展示前述内容（整体或部分），和/或将前述内容编入其他任何形式的作品、媒体、或技术中，不论是当前已知的或以后开发的。同时，用户同意网站在未改变其提供信息的。
        </p>
        <p class="user_protocol__text">
          4.9
          若用户违反本协议规定，或有违反国家及地方法律法规之行为，本平台有权停止传输并删除相关信息，屏蔽/禁止用户的发表、使用，注销账户，如有必要可依法向相关主管部门进行披露。
        </p>
        <p class="user_protocol__title">5、纠纷、争议及投诉处理</p>
        <p class="user_protocol__text">
          5.1
          用户在使用网站的过程中，发现可能有侵害自己权利的事实时，应及时通知网站并提供相应的证明材料。因投诉不实给网站或第三方造成损失的，应承担法律责任。 
        </p>
        <p class="user_protocol__text">
          5.2
          网站有权接受并处理用户间因使用网站而产生的纠纷、争议及投诉，有权通过电子邮件等联系方式了解情况、并将所了解的情况通过电子邮件等方式通知对方。用户有义务在收到网站通知后，在指定的时间内提供相应的资料，配合纠纷、争议及投诉的情况了解与处理。对网站的处理结果不满意的，用户可以依法向有管辖权的法院起诉。 
        </p>
        <p class="user_protocol__text">
          5.3
          经生效法律文书确认用户存在违法或违反本协议行为或者网站自行判断会员涉嫌存在违法或违反本协议行为的，网站有权公布用户的违法行为并做出相应处理，包括但不限于终止服务、永久禁止使用网站等。
          
        </p>
        <p class="user_protocol__title">6、中止与终止</p>
        <p class="user_protocol__title">
          对于用户的以下行为，网站有权在未告知的情况下中止或终止其相关服务或者拒绝其以任何形式使用网站，并保留追究您法律责任的权利：
        </p>
        <p class="user_protocol__text">
          6.1
          用户同意，本平台有权随时对网站、政策和/或本协议作出变更；任何修改或补充在其被张贴在网站时立即生效，对双方都有约束力。重大变更将在网站首页页面提示。用户继续使用网站、享受服务的，即视为对该等变更之确认。
        </p>
        <p class="user_protocol__text">
          6.2 如发生下列任何一种情形，网站有权无需通知而随时停止向用户提供服务：
          ①用户提供的个人资料不真实； ②用户违反本协议规定；
          ③按照行政机关和/或授权职能部门的要求；
          ④其他本平台认为需终止服务的情况本协议终止或更新时，用户未确认新的协议的其它本公司认为需终止服务的情况。
        </p>
        <p class="user_protocol__title">7、责任声明</p>
        <p class="user_protocol__text">
          7.1 鉴于网络服务的特殊性，网站不对除基本安全、稳定以外其的他外部干扰、黑客攻击等因素造成的用户与网站服务器之间的连接受阻而无法访问负责。用户同意自己承担因网站意外中断、操作或传输的迟延、电脑病毒、网络连接故。 
        </p>
        <p class="user_protocol__text">
          7.2
         因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本合同所称之不可抗力意指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、 罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。 
        </p>

        <p class="user_protocol__text">
          7.3
          本平台无法保证网络信息服务的准确性，对用户因使用网站而造成的损失不负法律责任。用户可通过本平台有关服务获得第三方的某些内容或通往第三方网站的链接，但不表示本平台与这些第三方有任何关系。第三方的网站均由各经营者自行负责，不属于本平台控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、产品或其它资料，本平台对此不作保证、不承担任何责任、也不负有任何义务。用户对此自行加以判断，并承担因使用该等内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。 
        </p>
        <p class="user_protocol__text">
          7.4
          用户明确同意其使用本平台服务所存在的风险及其后果将完全由其自己承担，网站对用户不承担任何责任。如因用户违反有关法律、法规或本协议项下的任何条款而给网站或任何其他第三人造成损失，用户同意承担由此造成的损害。
        </p>

        <p class="user_protocol__title">8、知识产权</p>
        <p class="user_protocol__text">
          8.1
          本平台及本平台所有内容的选材、整理、编排、排版、汇编，以及由网站创作的文字、图片等作品的著作权，无论是否明示，均属网站所有。任何自然人、法人及其他组织未经网站书面授权或许可，不得修改、出版、传播、参与传播、销售、制作衍生作品，或以其他任何方式使用整体或部分的网站上的内容，包括但不限于公司信息、产品信息，网站将保留追究其法律责任的权利。 
        </p>
        <p class="user_protocol__text">
          8.2
          未经网站授权，任何人不得使用任何商标、服务标记、标识、域名和其他显著品牌特征的权利。用户在上发布的信息不得侵犯任何第三人的知识产权，未经相关权利人之事先书面同意，用户不得以任何方式上传、发布、修改、传播或复制任何受著作权保护的材料、商标或属于其他人的专有信息。 
        </p>
        <p class="user_protocol__title">9、法律适用及管辖</p>
        <p class="user_protocol__text">
          9.1
          本协议的订立、变更、执行和解释及争议的解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项缺乏明确法律规定，则应参照通用的国际商业惯例和行业惯例。 
        </p>
        <p class="user_protocol__text">
          9.2
          因本协议内容或其执行发生争议，双方应友好协商解决；协商不成时，任何一方均可向公司经营所在地的人民法院提起诉讼。
        </p>
        <p class="user_protocol__title">10、其他</p>
        <p class="user_protocol__text">
          10.1
          用户使用网站相关服务时，亦应知晓、认可并同意遵守相关服务的额外条款及条件。
        </p>
        <p class="user_protocol__text">
          10.2 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。 
        </p>
        <p class="user_protocol__text">
          10.3
          本协议之条款是可分割的，任何协议条款被认定为无效或不可执行时，不影响其他条款之效力。
        </p>
        <p class="user_protocol__title">11、协议生效</p>
        <p class="user_protocol__text">
          本协议于用户勾选本协议时即刻生效，对本平台和用户均具有约束力。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "agreement",
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.agreement {
  height: 100vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.container {
  padding: 0.3rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
}
.user_protocol__title {
  font-size: 0.3rem;
  font-weight: bold;
  color: #fff;
}
.user_protocol__text {
  font-size: 0.3rem;
  color: #fff;
}
</style>